import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    GAME_DIALOGBOX_RESPONSE, GAME_FINISH_MOVEMENT,
    GAME_ROLL_DICES, GAME_ROLL_DICES_CONFIRM, GAME_START, LEAVE_GAME,
} from '../actions';

import history from '../../history';


import {sendMessage} from '../../helpers/ws';
import {GameDialogBoxResponseAction, WsMessageItemType} from "../../types";
import {rootPath} from "../../helpers/constants";

export function* watchGameRollDicesConfirm() {
    yield takeEvery(GAME_ROLL_DICES_CONFIRM, gameRollDicesConfirm);
}

export function* watchGameDialogBoxResponse() {
    yield takeEvery(GAME_DIALOGBOX_RESPONSE, gameDialogBoxResponse);
}

export function* watchGameStart() {
    yield takeEvery(GAME_START, gameStart);
}

export function* watchFinishMovement() {
    yield takeEvery(GAME_FINISH_MOVEMENT, finishMovement);
}

export function* watchLeaveGame() {
    yield takeEvery(LEAVE_GAME, leaveGame);
}


function gameRollDicesConfirm() {
    sendMessage({
        action: 'GAME_ROLL_DICES_CONFIRM',
        data: null
    });
}

function gameStart() {
    sendMessage({
        action: 'GAME_START',
        data: null
    });
}

function leaveGame() {
    sendMessage({
        action: 'GAME_LEAVE',
        data: null
    });
    history.push(`${rootPath}`)
}

function finishMovement() {
    sendMessage({
        action: 'GAME_FINISH_MOVEMENT',
        data: null
    });
}

function gameDialogBoxResponse(action: GameDialogBoxResponseAction) {
    const { dialogType, token } = action;
    sendMessage({
        action: 'GAME_DIALOGBOX_RESPONSE',
        data: {
            type: dialogType,
            dialogbox_token: token,
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(watchGameRollDicesConfirm),
        fork(watchGameStart),
        fork(watchLeaveGame),
        fork(watchGameDialogBoxResponse),
        fork(watchFinishMovement),
    ]);
}
