import {
    CREATE_GAME, CREATE_GAME_ERROR, CREATE_GAME_SUCCESS,
    GAME_GET_LIST, GAME_GET_LIST_ERROR, GAME_GET_LIST_SUCCESS, GAME_JOIN_AS_PLAYER, LEAVE_GAME

} from '../actions';
import {GameModel} from "../../types";

const INIT_STATE: State = {
    games: [],
    loading: false,
    creating: false,
    joining: false,
    error: '',
}

interface State {
    games: GameModel[],
    loading: boolean,
    creating: boolean,
    joining: boolean,
    error: string;
}

const lobbyReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GAME_GET_LIST:
            return {
                ...state,
                loading: true,
            };

        case GAME_GET_LIST_SUCCESS:
            return {
                ...state,
                games: [
                    ...action.games
                ],
                error: '',
                loading: false,
            };

        case GAME_JOIN_AS_PLAYER:
            return {
                ...state,
                joining: true,
            }

        case GAME_GET_LIST_ERROR:
            return {
                ...state,
                error: '',
                loading: false,
            };

        case CREATE_GAME:
            return {
                ...state,
                creating: true
            }

        case CREATE_GAME_SUCCESS: {
                return {
                    ...state,
                    creating: false,
                }
        }

        case CREATE_GAME_ERROR: {
                return {
                    ...state,
                    creating: false,
                    error: action.msg,
                }
        }

        case LEAVE_GAME:
            return {
                ...state,
                loading: true,
                creating: false,
                joining: false,
            }


        default:
            return state;
    }
};

export default lobbyReducer;
