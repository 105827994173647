import Field from "../views/app/game/Field";
import {FieldColors} from "../types";

export const rootPath = '/app';



export const fieldsColors: FieldColors  = {
    2: '#0072bc',
    4: '#0072bc',

    7: '#00a651',
    9: '#00a651',
    10: '#00a651',

    12: '#f26d7d',
    14: '#f26d7d',
    15: '#f26d7d',

    17: '#f26522',
    19: '#f26522',
    20: '#f26522',

    22: '#ed1c24',
    24: '#ed1c24',
    25: '#ed1c24',

    27: '#8842b4',
    28: '#8842b4',
    30: '#8842b4',

    32: '#fff568',
    33: '#fff568',
    35: '#fff568',

    38: '#8c6239',
    40: '#8c6239',
}
