import {
    CREATE_GAME, CREATE_GAME_ERROR,
    CREATE_GAME_SUCCESS,
    GAME_GET_LIST,
    GAME_GET_LIST_ERROR,
    GAME_GET_LIST_SUCCESS, GAME_JOIN_AS_PLAYER,
} from "../actions";
import {GameModel} from "../../types";

export const gameGetList = () => ({
    type: GAME_GET_LIST,
});

export const gameGetListSuccess = (games: GameModel[]) => ({
    type: GAME_GET_LIST_SUCCESS,
    games
});

export const gameGetListError = (msg: string) => ({
    type: GAME_GET_LIST_ERROR,
    msg,
});

export const createGame = () => ({
    type: CREATE_GAME,
})

export const createGameSuccess = () => ({
    type: CREATE_GAME_SUCCESS
});

export const createGameError = (msg: string,) =>  ({
    type: CREATE_GAME_ERROR,
    msg,
});

export const gameJoinAsPlayer = (gameToken: string) => ({
    type: GAME_JOIN_AS_PLAYER,
    gameToken,
})
