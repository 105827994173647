import {localeOptions} from "../lang";

export const defaultLocale = () => {
    if (navigator.language !== undefined) {
        try {
            const { language } = navigator;
            return localeOptions.some((l) => l.id === language) ? language : 'pl';
        } catch (e) {
            return 'pl';
        }
    }
    return 'pl';
};

export const getCurrentLanguage = (): string => {
    try {
        const lang = localStorage.getItem('lang');
        if(lang) return lang;
        return defaultLocale();
    } catch {
        return defaultLocale();
    }
}

export const getSessId = (): string => {
    const sessId = localStorage.getItem('sessId');
    return sessId || '';
}
