import React from 'react';
import logo from '../assets/images/logo.svg';
import {RouteComponentProps } from "react-router-dom";




const ErrorPage = (props: RouteComponentProps) => {
    const { history } = props;


    return (<div className="ErrorPage">
        <img src={logo} className="Login__logo" alt="COSMOpoly" />
        BLAD
    </div>)
}

export default ErrorPage;
