import plLang from './entries/pl';
import { getCurrentLanguage } from '../helpers/Utils';

interface Locale {
    [key: string]: any
}

export const AppLocale: Locale = {
    pl: plLang,
};

export const localeOptions = [
    { id: 'pl', name: 'Polski' },
];

const locale:string = getCurrentLanguage();
const l = AppLocale[locale];

export default function t(index: string) {
    if (!index) {
        return l;
    }
    const s = index.split(':');
    if (s.length > 1) {
        try {
            return l.messages[s[0]][s[1]] || '';
        } catch (e) {
            return index;
        }
    } else {
        try {
            return l.messages[index] || '';
        } catch (e) {
            return index;
        }
    }
}

export function messages() {
    return Object.assign({}, ...Object.values(l.messages));
}
