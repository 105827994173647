import { all } from 'redux-saga/effects';
import authSagas from './app/saga';
import lobbySagas from './lobby/saga';
import gameSagas from './game/saga';

export default function* rootSaga() {
    yield all([
        authSagas(),
        lobbySagas(),
        gameSagas(),
    ]);
}
