import {
    BOARD_STRUCTURE, GAME_ALLOW_TO_FINISH_MOVEMENT, GAME_DIALOGBOX, GAME_DIALOGBOX_RESPONSE, GAME_FINISH_MOVEMENT,
    GAME_FULL_STATUS, GAME_PLAYER_TOKEN, GAME_ROLL_DICES, GAME_ROLL_DICES_CONFIRM, GAME_ROLL_DICES_RESULT,
} from '../actions';
import {Board, DialogBox, GameFullStatus, GamePlayer} from "../../types";


const INIT_STATE: State = {
    players: [],
    board: null,
    loadingBoard: true,
    processing: false,
    dice1: null,
    dice2: null,
    loadingGameStatus: true,
    status: null,
    player_token: null,
    game_token: null,
    owner_player_token: null,
    property_relations: null,
    buildings: null,
    pledges: null,
    dialogBox: null,
    current_player_movement_token: null,
    canRollDice: false,
    allowFinishMovement: false,
}

interface State {
    players: GamePlayer[],
    board: Board|null,
    dice1: number|null,
    dice2: number|null,
    canRollDice: boolean,
    processing: boolean,
    loadingGameStatus: boolean,
    loadingBoard: boolean,
    dialogBox: DialogBox|null,
    status: GameFullStatus['status'] | null,
    player_token: GameFullStatus['player_token'] | null,
    game_token: GameFullStatus['game_token'] | null,
    owner_player_token: GameFullStatus['owner_player_token'] | null,
    property_relations: GameFullStatus['property_relations'] | null,
    buildings: GameFullStatus['buildings'] | null,
    pledges: GameFullStatus['pledges'] | null,
    allowFinishMovement: boolean;
    current_player_movement_token: GameFullStatus['current_player_movement_token']
}

const gameReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GAME_FULL_STATUS:
            return {
                ...state,
                players: [
                    ...action.status.players
                ],
                status: action.status.status,
                game_token: action.status.game_token,
                owner_player_token: action.status.owner_player_token,
                property_relations: action.status.property_relations,
                buildings: action.status.buildings,
                pledges: action.status.pledges,
                current_player_movement_token: action.status.current_player_movement_token,
                loadingGameStatus: false,
                processing: false,
            };

        case GAME_PLAYER_TOKEN:
            return {
                ...state,
                player_token: action.token,
            }

        case BOARD_STRUCTURE:
            return {
                ...state,
                board: {
                    ...action.board,
                },
                loadingBoard: false,
            }

        case GAME_ROLL_DICES_CONFIRM:
            return {
                ...state,
                processing: true,
                canRollDice: false,
            }

        case GAME_ROLL_DICES_RESULT:
            return {
                ...state,
                dice1: action.dice1,
                dice2: action.dice2,
                current_player_movement_token: action.current_player_movement_token,
                processing: false,
            }

        case GAME_ROLL_DICES:
            return {
                ...state,
                processing: false,
                canRollDice: true,
            }

        case GAME_DIALOGBOX:
            return {
                ...state,
                dialogBox: action.dialogBox,
            }

        case GAME_DIALOGBOX_RESPONSE:
            return {
                ...state,
                dialogBox: null,
            }

        case GAME_FINISH_MOVEMENT:
            return {
                ...state,
                allowFinishMovement: false,
            };

        case GAME_ALLOW_TO_FINISH_MOVEMENT:
            return {
                ...state,
                allowFinishMovement: true,
            }


        default:
            return state;
    }
};

export default gameReducer;
