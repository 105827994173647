import React from 'react';

interface Props {
    onClose: () => void;
    children?: React.ReactChild  | React.ReactChild[];
}

const Modal = ({ children, onClose }: Props) => {

    return (
        <div className="Modal" onClick={onClose} >
            <div className="ModalBody">
                {children}
            </div>
        </div>
        )

}

export default Modal;
