import React from 'react';

interface Props {
    hideText?: boolean
    className?: string
}

const Loading = ({hideText, className}: Props ) => (
    <div className={`Loading ${className}`}>
        <div className="d-flex flex-column justify-content-center align-items-center">
            <img src={`${process.env.PUBLIC_URL}/assets/img/loader.svg`} alt="Ladowanie" />
            {!hideText ? <span className="mt-2">Ładowanie...</span> : ''}
        </div>

    </div>
)
export default Loading;
