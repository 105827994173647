import React, {ExoticComponent } from 'react';
import {Route, Redirect, RouteComponentProps} from 'react-router-dom';


interface Props {
    sessId: string|null;
    username: string|null;
    path: string;
    connected: boolean;
    loading: boolean;
    component: ExoticComponent<RouteComponentProps>
}

const ProtectedRoute = ({
    sessId,
    loading,
    connected,
    username,
    component: Component,
    ...rest
}: Props) => {
    const setComponent = (props: RouteComponentProps) => {
        if(sessId && username) {
            return <Component {...props} />
        }
        return  <Redirect
            to={{
                pathname: '/login',
                state: { from: props.location }
            }}
        />
    }

    return (<Route {...rest} render={setComponent} />);
}


export default ProtectedRoute;
