import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Redirect, Route, Switch, useHistory} from 'react-router-dom';
import './assets/styles/App.scss';
import ProtectedRoute from './helpers/ProtectedRoute';
import {rootPath} from "./helpers/constants";
import {RootState} from "./redux/store";
import ErrorPage from "./views/ErrorPage";
import DebugRouter from "./views/DebugRouter";
import {startConnection} from "./redux/app/actions";
import AppLayout from "./views/app/AppLayout";


const ViewApp = React.lazy(() => import('./views/app'));
const ViewLogin = React.lazy(() => import('./views/login'));

const selectSessId = (state: RootState) => state.app.sessId
const selectUsername = (state: RootState) => state.app.username;
const selectConnected = (state: RootState) => state.app.connected;
const selectLoading = (state: RootState) => state.app.loading;

const App = () => {
    const dispatch = useDispatch();
    const sessId = useSelector(selectSessId)
    const username = useSelector(selectUsername)
    const connected = useSelector(selectConnected);
    const loading = useSelector(selectLoading);

    useEffect(() => {
        dispatch(startConnection());
    }, [])

    return (
        <AppLayout>
            <Switch>
                <ProtectedRoute path={rootPath} component={ViewApp} sessId={sessId} connected={connected} username={username} loading={loading}/>
                <Route path="/login" render={(props) => (!username ? <ViewLogin {...props}/> : <Redirect to={rootPath} />)} />
                <Route path="/error" render={(props) => <ErrorPage {...props}/>} />
                <Redirect exact from="/" to={rootPath} />
            </Switch>
        </AppLayout>
    );
}

export default App;

