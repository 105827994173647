import { combineReducers } from 'redux';
import app from './app/reducer';
import lobby from './lobby/reducer';
import game from './game/reducer';

const reducers = combineReducers({
    app,
    lobby,
    game
});

export default reducers;
