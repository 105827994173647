import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    CREATE_GAME,
    GAME_GET_LIST, GAME_JOIN_AS_PLAYER,
} from '../actions';


import {sendMessage} from '../../helpers/ws';
import {GameJoinAsPlayerAction} from "../../types";

export function* watchGetGameList() {
    yield takeEvery(GAME_GET_LIST, getGameList);
}

export function* watchCreateGame() {
    yield takeEvery(CREATE_GAME, createGame);
}


export function* watchGameJoinAsPlayer() {
    yield takeEvery(GAME_JOIN_AS_PLAYER, gameJoinAsPlayer);
}

function createGame() {
    sendMessage({
        action: 'GAME_CREATE',
        data: null
    });
}


function getGameList() {
    sendMessage({
        action: 'GAME_GET_LIST',
        data: null
    });
}

function gameJoinAsPlayer(action: GameJoinAsPlayerAction) {
    const { gameToken } = action;
    sendMessage({
        action: 'GAME_JOIN_AS_PLAYER',
        data: gameToken
    });
}




export default function* rootSaga() {
    yield all([
        fork(watchGetGameList),
        fork(watchCreateGame),
        fork(watchGameJoinAsPlayer)
        // fork(watchGetGameListSuccess)
    ]);
}
