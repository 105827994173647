import {Board, DialogBox, DialogBoxOptionType, GameDicesResult, GameFullStatus} from "../../types";
import {
    BOARD_STRUCTURE, GAME_ALLOW_TO_FINISH_MOVEMENT, GAME_DIALOGBOX, GAME_DIALOGBOX_RESPONSE, GAME_FINISH_MOVEMENT,
    GAME_FULL_STATUS,
    GAME_PLAYER_TOKEN,
    GAME_ROLL_DICES, GAME_ROLL_DICES_CONFIRM,
    GAME_ROLL_DICES_RESULT,
    GAME_START, LEAVE_GAME
} from "../actions";

export const gameFullStatus = (status: GameFullStatus) => ({
    type: GAME_FULL_STATUS,
    status
});

export const boardStructure = (board: Board) => ({
    type: BOARD_STRUCTURE,
    board,
});

export const gameStart = () => ({
    type: GAME_START,
});

export const gameRollDices = () => ({
    type: GAME_ROLL_DICES,
});

export const gameRollDicesResult = (dices: GameDicesResult) => ({
    type: GAME_ROLL_DICES_RESULT,
    dices,
});

export const gamePlayerToken = (token: string) => ({
    type: GAME_PLAYER_TOKEN,
    token,
});

export const leaveGame = () => ({
    type: LEAVE_GAME,
});

export const gameDialogBox = (dialogBox: DialogBox) => ({
    type: GAME_DIALOGBOX,
    dialogBox
})

export const gameDialogBoxResponse = (dialogType: DialogBoxOptionType, token: DialogBox['dialogbox_token']) => ({
    type: GAME_DIALOGBOX_RESPONSE,
    dialogType,
    token,
})

export const gameRollDicesConfirm = () => ({
    type: GAME_ROLL_DICES_CONFIRM,
});

export const gameFinishMovement = () => ({
    type: GAME_FINISH_MOVEMENT,
});

export const gameAllowToFinishMovement = () => ({
    type: GAME_ALLOW_TO_FINISH_MOVEMENT
});
