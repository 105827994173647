import t from '../lang';
import {Connection, WSMessage, WSMessageData} from "../types";
let sock: WebSocket;

interface CreateConnectionOpts {
  url: string,
  onMessage: (m: WSMessage) => void,
  onClose: () => void,
}

const createConnection = async (opts: CreateConnectionOpts):Promise<Connection> => new Promise((resolve, reject) => {
  const { url, onMessage, onClose } = opts;
  console.log(onMessage);
  console.log('CONNECTING...')
  const token = sessionStorage.getItem('token') ;
  sock = new WebSocket(`${url}${token ? `?t=${token}` : ''}`);

  sock.onopen = (e:any) => {
    console.log(e);
    // eslint-disable-next-line
    const target = e.target!;
    resolve({
      sock,
      status: target.readyState,
    });
  };

  sock.onmessage = function (e: MessageEvent) {
    const data: WSMessage = JSON.parse(e.data);
    console.log('message');
    onMessage(data);
  }

  sock.onerror = (e: any) => {
    reject('ERROR');
  }

  sock.addEventListener('close', () => {
    console.log('Closed');
    onClose();
  });

  // Close conection after close window
  window.onbeforeunload = function() {
    sock.onclose = function () {}; // disable onclose handler first
    sock.close();
  };
})

export const sendMessage = async ({action, data}: WSMessageData) => {
  console.log('sendnij message');
  console.log(action);
  try {
    sock.send(JSON.stringify({
      action,
      data
    }));
  } catch {
    throw new Error(t('error:send-message-error'))
  }
}


export default createConnection;
